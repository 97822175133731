<script>
    import BaseSelect from './BaseSelect';

    /**
     * N.B. The difference between this select and the SmartAjaxSelect is
     * that this select sends an ajax request every time the user
     * types in the search box with a delay of 250ms.
     */
    export default {
        extends: BaseSelect,

        props: {
            dataUrl: {
                type: String,
                required: true,
            },

            initialData: {
                type: Array,
                default() {
                    return [];
                },
            },

            excludeValues: {
                type: Array,
                default() {
                    return [];
                },
            },

            newLabel: {
                type: String,
                default: null,
            },

            queryData: {
                type: Object,
                default: () => {},
            },
        },

        mounted() {
            this.init({
                ajax: {
                    delay: 250,
                    url: this.dataUrl,
                    data: (params) => ({
                        ...params,
                        ...this.queryData,
                    }),
                    dataType: 'json',
                    processResults: (data) => ({
                        ...data,
                        results: [...data.results
                            .filter(result => !this.excludeValues.includes(result.value))
                            .map(this.fixDataFormat),
                            // append the results with the new option if newLabel is set
                            ...(this.newLabel ? [{
                                id: '__NEW__',
                                text: this.newLabel,
                            }] : []),
                        ],
                    }),
                },
                data: this.initialData.map(this.fixDataFormat),
                minimumInputLength: 1,
            });
        },
    };
</script>

<script>
import axios from 'axios';
import BaseSelect from './BaseSelect';
import updateQueryStringParameter from '../../../services/updateQueryStringParameter';

/**
 * N.B. The difference between this select and the SmartServerSelect is
 * that this select only sends an ajax request once.
 */
export default {
    extends: BaseSelect,

    props: {
        dataUrl: {
            type: String,
            required: true,
        },

        dataParams: {
            type: Object,
            default: () => ({}),
        },

        selectAllOption: {
            type: String,
            default: '',
        },
    },

    mounted() {
        this.loading = true;

        let url = this.dataUrl;
        if (this.value) {
            url = updateQueryStringParameter(url, 'include', Array.isArray(this.value) ? this.value.join() : this.value);
        }

        axios
            .get(url, { params: this.dataParams })
            .then(response => response.data.data)
            .catch(() => [])
            .then(data => data.map(this.fixDataFormat))
            .then(data => {
                if (!this.selectAllOption) {
                    return data;
                }

                data = [{ id: '__ALL__', text: this.selectAllOption }, ...data];

                return data;
            })
            .then(data => {
                this.init({ data, placeholder: this.selectAllOption ? this.selectAllOption : this.placeholder });

                if (this.selectAllOption) {
                    this.$options.instance.on('select2:select', evt => {
                        if (evt.params.data.id === '__ALL__') {
                            this.$options.instance.val(null).trigger('change');
                        }
                    });

                    this.$options.instance.on('change', () => {
                        this.$options.instance.find('option[value="__ALL__"]').attr('disabled', !this.$options.instance.val().length);
                    });
                }
            })
            .finally(() => {
                this.loading = false;
            });
    },
};
</script>

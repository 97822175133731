<script>
    import BaseSelect from './BaseSelect';

    export default {
        extends: BaseSelect,

        props: {
            data: {
                type: Array,
                required: true,
            },
        },

        watch: {
            data: {
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                        return;
                    }

                    this.$options.instance.select2().empty();
                    this.init({ data: newValue.map(this.fixDataFormat) });
                },
                deep: true,
            },
        },

        mounted() {
            this.init({ data: this.data.map(this.fixDataFormat) });
        },
    };
</script>

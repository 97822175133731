<template>
    <div :class="{'smart-select--required': required, 'smart-select--disabled': disabled, 'smart-select--multiple': multiple}" class="smart-select">
        <select
            :id="id"
            ref="select"
            :disabled="disabled || loading"
            :multiple="multiple"
            :name="name"
            :required="required"
            class="smart-select__original"
        >
            <option v-if="loading" :value="null">
                {{ $t('form.smart-select.loading') }}
            </option>
            <option v-if="placeholder && !value && !loading" />
        </select>
    </div>
</template>

<script>
    import $ from 'jquery';
    import 'select2';

    const newValuePlaceholder = '__NEW__';

    /* @see https://stackoverflow.com/a/32084193/6194446 */
    function categoryAndItemMatcher(params, data) {
        data.parentText = data.parentText || '';

        // Always return the object if there is nothing to compare
        if ($.trim(params.term) === '') {
            return data;
        }

        // Do a recursive check for options with children
        if (data.children && data.children.length > 0) {
            // Clone the data object if there are children
            // This is required as we modify the object to remove any non-matches
            const match = $.extend(true, {}, data);

            // Check each child of the option
            for (let c = data.children.length - 1; c >= 0; c--) {
                const child = data.children[c];
                child.parentText = data.parentText + ' ' + data.text;

                const matches = categoryAndItemMatcher(params, child);

                // If there wasn't a match, remove the object in the array
                if (matches == null) {
                    match.children.splice(c, 1);
                }
            }

            // If any children matched, return the new object
            if (match.children.length > 0) {
                return match;
            }

            // If there were no matching children, check just the plain object
            return categoryAndItemMatcher(params, match);
        }

        // If the typed-in term matches the text of this term, or the text from any
        // parent term, then it's a match.
        const original = (data.parentText + ' ' + data.text).toUpperCase();
        const term = params.term.toUpperCase();

        // Check if the text contains the term
        if (original.indexOf(term) > -1) {
            return data;
        }

        // If it doesn't contain the term, don't return anything
        return null;
    }

    export default {
        props: {
            name: {
                type: String,
                default: null,
            },

            id: {
                type: String,
                default: null,
            },

            value: {
                type: [Number, String, Array],
                default: null,
            },

            placeholder: {
                type: String,
                default: '',
            },

            required: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            multiple: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        watch: {
            value(newValue) {
                if (!this.valuesEqual(this.getValueFromSelect(), newValue)) {
                    this.$options.instance.val(newValue).trigger('change');
                }
            },
        },

        methods: {
            init(options) {
                this.$options.instance = $(this.$refs.select);
                this.$options.instance
                    .select2({
                        matcher: categoryAndItemMatcher,
                        width: '100%',
                        language: {
                            errorLoading: () => this.$t('form.smart-select.error-loading'),
                            inputTooLong: (args) => this.$t('form.smart-select.input-too-long', { amount: args.input.length - args.maximum }),
                            inputTooShort: (args) => this.$t('form.smart-select.input-too-short', { amount: args.minimum - args.input.length }),
                            loadingMore: () => this.$t('form.smart-select.loading'),
                            maximumSelected: (args) => this.$tc('form.smart-select.maximum-selected', args.maximum, { amount: args.maximum }),
                            noResults: () => this.$t('form.smart-select.no-results-found'),
                            removeAllItems: () => this.$t('form.smart-select.remove-all-items'),
                            searching: () => this.$t('form.smart-select.searching'),
                        },
                        placeholder: this.placeholder,
                        ...options,
                    })
                    .on('select2:selecting', e => {
                        if (e.params.args.data.id !== newValuePlaceholder) {
                            return;
                        }

                        const search = this.multiple
                            ? this.$options.instance.data('select2').selection.$search
                            : this.$options.instance.data('select2').dropdown.$search;

                        this.$emit('new', search.val());
                        search.val('');

                        this.$options.instance.select2('close');

                        // Returning false in order to prevent the newValuePlaceholder to be selected.
                        return false;
                    })
                    .on('change', () => {
                        const value = this.getValueFromSelect();

                        this.$emit('input', value);
                        this.$emit('change', value);
                    });

                setTimeout(() => {
                    this.$options.instance.val(this.value).trigger('change');
                });
            },

            newValueSelected() {
                return this.multiple
                    ? this.$options.instance.val()?.some(value => value === newValuePlaceholder)
                    : this.$options.instance.val() === newValuePlaceholder;
            },

            getValueFromSelect() {
                let value = this.$options.instance.val();

                if (value === null) {
                    return null;
                }

                if (this.multiple) {
                    if (value.length === 1 && value[0] === '') {
                        value = [];
                    }

                    value = value.filter(value => value !== newValuePlaceholder);
                } else {
                    value = Array.isArray(value) ? value[0] : value;

                    if (value === '' || value === newValuePlaceholder) {
                        value = null;
                    }
                }

                return value;
            },

            valuesEqual(value1, value2) {
                if (!this.multiple) {
                    return value1 === value2;
                }

                if (value1.length !== value2.length) {
                    return false;
                }

                for (let i = value1.length; i--;) {
                    if (value1[i] !== value2[i]) {
                        return false;
                    }
                }

                return true;
            },

            fixDataFormat(item) {
                const { value, label, children, ...copy } = item;
                copy.id = copy.id || value;
                copy.text = copy.text || label;

                if (children) {
                    copy.children = children.map(this.fixDataFormat);
                }

                return copy;
            },
        },

        instance: null,
    };
</script>
